<template>
  <v-list expand nav v-bind="$attrs" v-on="$listeners">
    <template v-for="(item, i) in items">
      <default-list-group v-if="item.items" :key="`group-${i}`" :item="item" />

      <default-list-item
        v-if="!item.items && canAccess(item.title)"
        :key="`item-${i}`"
        :item="item"
      />
    </template>
  </v-list>
</template>

<script>
export default {
  name: "DefaultList",

  components: {
    DefaultListGroup: () => import("./ListGroup"),
    DefaultListItem: () => import("./ListItem"),
  },

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({}),

  computed: {
    permissions() {
      return this.$store.getters["authUser/permissions"];
    },
  },

  methods: {
    canAccess(name) {
      switch (name) {
        case "Dashboard":
          return true;
        case "Settings":
          return this.permissions.includes("Permissions.Settings.ViewAll");
        default:
          return true;
        // only true when testing
      }
    },
  },
};
</script>
